import React, { useEffect } from 'react';
import './App.css';
import Header from './Components/Header';
import Body from './Components/Body';
import Footer from './Components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Headers from './Components/SpeakPage/Headers';
import Main from './Components/CallPage/Main';
import FeedPage from './Components/FeedBack/FeedPage';
import FirstPage from './Components/FirstPage/FirstPage';
import WebPage from './Components/WebPage/WebPage';
import Permission from './Components/Permission';
import GoLive from './Components/GoLive';
import { DataProvider } from './Context';
import { ConfigProvider } from './ConfigContext';
import { StateProvider } from './StateProvider'; // Import StateProvider
import Carouselfullscreen from './Components/CarouselFullScreen/CarouselFullScreen';
import Videos from './Components/ShopableVideos/Videos';
import { hostingURL } from "../src/Components/constant";
import {clearCachedMedia} from "../src/utils/indexedDBHelper.js";


// Initial state and reducer for StateProvider
const initialState = {
  user: { displayName: "Guest User" },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.user };
    default:
      return state;
  }
};

function App() {
  useEffect(() => {
    const checkForUpdates = async () => {
      try {
        const firebaseBaseURL = window.location.origin; // Gets Firebase hosting origin dynamically
        const response = await fetch(`${hostingURL}version.json`, { cache: "no-store" });
        const { version } = await response.json();
        const storedVersion = localStorage.getItem("saro-AppVersion");

        if (storedVersion !== version) {
          console.log("New version detected! Reloading...");
          // Clear IndexedDB & Local Storage
          await clearCachedMedia();
          localStorage.clear();
          
          localStorage.setItem("saro-AppVersion", version);
          //window.location.reload(true); // Force refresh to get latest files

          // Refresh JS & CSS assets
          //refreshAssets();
        }
      } catch (error) {
        console.error("Error checking version:", error);
      }
    };

    checkForUpdates(); // Run on initial load
    const interval = setInterval(checkForUpdates, 30000); // Check every 30 seconds
    //const interval = setInterval(checkForUpdates, 3600000); // Check every 1 hour

    return () => clearInterval(interval);
  }, []);

  return (
    <Router>
      <div className="app-container">
        <StateProvider initialState={initialState} reducer={reducer}> {/* Wrap with StateProvider */}
          <DataProvider>
            <ConfigProvider>
              <Routes>
                <Route path="*" element={<WebPage />} />
                <Route path="Components/GoLive" element={<GoLive />} />
                <Route path="Components/Body" element={<Body />} />
                <Route path="SpeakPage/Headers" element={<Headers />} />
                <Route path="CallPage/Main" element={<Main />} />
                <Route path="FeedBack/FeedPage" element={<FeedPage />} />
                <Route path="FirstPage/FirstPage" element={<FirstPage />} />
                <Route path="WebPage/WebPage" element={<WebPage />} />
                <Route path="Components/Permission" element={<Permission />} />
                <Route path="Components/CarouselFullScreen/:videoID" element={<Carouselfullscreen />} />
                <Route path="ShopableVideos" element={<Videos />} />
              </Routes>
            </ConfigProvider>
          </DataProvider>
        </StateProvider>
      </div>
    </Router>
  );
}

/**
 * Refreshes JavaScript and CSS files to prevent cache issues.
 */
const refreshAssets = () => {
  console.log("Refreshing cached assets...");

  // Refresh CSS files
  document.querySelectorAll("link[rel='stylesheet']").forEach((link) => {
    const newHref = link.href.split("?")[0] + "?v=" + new Date().getTime();
    link.href = newHref;
  });

  // Refresh JavaScript files (excluding inline scripts)
  document.querySelectorAll("script").forEach((script) => {
    if (script.src) { // Only refresh external scripts
      const newScript = document.createElement("script");
      newScript.src = script.src.split("?")[0] + "?v=" + new Date().getTime();
      newScript.defer = true;

      script.parentNode.replaceChild(newScript, script);
    }
  });

  console.log("Assets updated!");
};

export default App;
